import React from "react";

class Construct extends React.Component{
    render() {
        return(
            <section className="construct-section">
                <div className="construct-logo"></div>
                <h2>Էջը գտնվում է կառուցման փուլում</h2>
                <p>Խնդրում ենք մուտք գործել ավելի ուշ:</p>
            </section>
        );
    }
}
export default Construct;