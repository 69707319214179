import React from "react";

class Section2 extends React.Component{

    openTab = (tab) => {
        tab = document.querySelector('.tab');
        tab.style.display = 'flex';
    }

    render() {
        return(
            <section className="main-sec-2">
                <div className="sec-2-left">

                </div>
                <div className="sec-2-right">
                    <h3>
                        <span className="sec-2-line"></span>
                        ՄԵՐ ՄԱՍԻՆ
                    </h3>
                    <h2>Մենք առաջարկում ենք պրոֆեսիոնալ լուծումներ ձեր բիզնեսի համար</h2>
                    <p>Ինչպես ունենալ որակյալ կայք համացանցում, և լինել առաջինը՞ պարզապես պատվիրիր քո կայքը digicode-ում։</p>
                    <p className="sec-2-p">
                        <span></span>
                        ԿԱՅՔԻ ՃԻՇՏ ՄՇԱԿՈՒՄ ԵՎ ԱՌԱՋԽԱՂԱՑՈՒՄ
                    </p>
                    <p className="sec-2-p">
                        <span></span>
                        ԺԱՄԱՆԱԿԱԿԻՑ ԵՎ ՆՈՐԱՈՃ ԴԻԶԱՅՆ
                    </p>
                    <p className="sec-2-p">
                        <span></span>
                        ՊԱՏՎԵՐԻ ՀԱՆՁՆՈՒՄ ՃԻՇՏ ԺԱՄԱՆԱԿԻՆ
                    </p>
                    <button className="call-button" onClick={this.openTab}>պատվիրել կայք</button>
                </div>
            </section>
        );
    }
}
export default Section2;