import React from "react";

class Contact extends React.Component{
    render() {
        return(
            <main className="contact-main">
                <div className="contact-content">
                    <div className="contact-tit">
                        <h1>Կապվել մեզ հետ</h1>
                        <p>օգտագործեք մեր նամակագրության դաշտը Բոլոր տեսակի տեղեկատվության հարցումների համար կամ ուղղակի կապվեք մեզ հետ՝ օգտագործելով ներքևում նշված հեռախոսահամարները</p>
                        <span className="contact-line"></span>
                    </div>
                    <div className="contact-cont">
                        <div className="contact-box">
                            <h2>
                                <span className="location-icon"></span>
                                Մեր գտնվելու վայրը
                            </h2>
                            <p>Digicode ծրագրավորման Ընկերություն ք․ Երևան</p>
                        </div>
                        <div className="contact-box">
                            <h2>
                                <span className="call-icon"></span>
                                Հեռախոսահամար
                            </h2>
                            <p>+374 77 736-770</p>
                        </div>
                    </div>
                </div>
                <div className="contact-mesage">
                    <div className="message-tit">
                        <h2>ՈՒղարկել նամակ</h2>
                    </div>
                    <div className="contact-form-box">
                        <form action="" method="post">
                            <div className="contact-input-box">
                                <p>Անուն</p>
                                <input type="text" placeholder="Անուն"/>
                            </div>
                            <div className="contact-input-box">
                                <p>Էլ․ Հասցե</p>
                                <input type="email" placeholder="Էլ․ Հասցե"/>
                            </div>
                            <div className="contact-input-box">
                                <p>Նամակ</p>
                                <textarea name="" id="" cols="30" rows="10" placeholder="գրել․․․"></textarea>
                            </div>
                            <div className="contact-button-box">
                                <button>ՈՒղարկել</button>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        );
    }
}
export default Contact;