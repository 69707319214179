import React from "react";
import './App.css';
import './components/css/media.css';
import Header from './components/header';
import Main from './components/template/main';
import Footer from './components/footer';
import About from './components/page/about';
import Contact from "./components/page/contact";
import Courses from "./components/page/courses";
import Reg from "./components/page/register";
import Construct from "./components/page/construct";
import Notfound from "./components/page/404";
import { Routes, Route } from 'react-router-dom';

class App extends React.Component{
  render() {
    return(
      <>
        <Header />
        <Routes>
          <Route path="*" element={<Notfound />} />
          <Route path="/" element={<Main />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="courses" element={<Courses />} />
          <Route path="register" element={ <Reg />} />
          <Route path="construct" element={ <Construct /> } />
        </Routes>
        <Footer />
      </>
    );
  }
}
export default App;