import React from "react";

class Section1 extends React.Component{

    openTab = (tab) => {
        tab = document.querySelector('.tab');
        tab.style.display = 'flex';
    }

    render() {
        return(
            <section className="main-sec-1">
                <div className="section-1-left">
                    <h1>ՍՏԵՂԾԵՔ ՁԵՐ ԿԱՅՔԸ DIGICODE-Ի ՀԵՏ ՄԻԱՍԻՆ</h1>
                    <button className="call-button" onClick={this.openTab}>
                        <span className="call-anim top-anim">զանգահարել</span>
                        <span className="call-anim bottom-anim">զանգահարել</span>
                    </button>
                </div>
                <div className="section-1-right">
                    <div className="sec-1-cont">
                        
                        <div className="sec-1-cont-row">
                            <div className="sec-1-row-cont">
                                <div className="sec-1-bg">
                                    <img src={require('../image/strategy.png')} alt="" />
                                </div>
                                <p>ՍՏՐԱՏԵԳԻԱ</p>
                            </div>
                            <div className="sec-1-row-cont">
                                <div className="sec-1-bg">
                                    <img src={require('../image/design.png')} alt="" />
                                </div>
                                <p>ԴԻԶԱՅՆ</p>
                            </div>
                        </div>

                        <div className="sec-1-cont-row">
                            <div className="sec-1-row-cont">
                                <div className="sec-1-bg">
                                    <img src={require('../image/programing.png')} alt="" />
                                </div>
                                <p>ԾՐԱԳՐԱՎՈՐՈՒՄ</p>
                            </div>
                            <div className="sec-1-row-cont">
                                <div className="sec-1-bg">
                                    <img src={require('../image/testing.png')} alt="" />
                                </div>
                                <p>ԹԵՍՏԱՎՈՐՈՒՄ</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        );
    }
}
export default Section1;