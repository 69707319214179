import React from "react";

class About extends React.Component{
    render() {
        return(
            <main className="about-main">
                <section className="about-sec-1">
                    <div className="about-tit-box">
                        <h1>Մեր ընկերության և նախագծի մասին</h1>
                        <span className="about-line"></span>
                    </div>
                    <div className="about-cont-box">
                        <div className="about-cont-left">
                            <h2>Մեր նպատակը</h2>
                            <p>Դասընթացների պլատֆորմի մշակում, որով ցանկացած ոք, անկախ փորձից և գիտելիքներից, կկարողանա սովորել և ստեղծել իր կայքը կամ հաճախորդի կայքը։</p>
                        </div>
                        <div className="about-cont-right">
                            <h2>Ինչ ենք մենք անում</h2>
                            <p>մի կողմից՝ մենք մշակում ենք գործիքներ անվճար Դասընթացների համար, որոնք կոգնեն շատ արագ հասնել արդյունքի։ մյուս կողմից, մենք այս գործիքները դարձնում ենք հնարավորինս պարզ և հասկանալի։</p>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
export default About;