import React from 'react';
import {Link} from 'react-router-dom';

class Courses extends React.Component{

    htmlTab = (left) => {
       left = document.querySelectorAll('.course-slide');
       let htmlWidth = document.querySelector('.html-link-course');
       let jsWidth = document.querySelector('.js-link-course');
       let frontWidth = document.querySelector('.front-link-course');
       let backWidth = document.querySelector('.back-link-course');

       for(let i=0; i<left.length; i++) {
        left[i].style.transform = 'translate(0)';
       }

       htmlWidth.style.width = '200px';
       jsWidth.style.width = '180px';
       frontWidth.style.width = '180px';
       backWidth.style.width = '180px';
    }

    jsTab = (left) => {
        left = document.querySelectorAll('.course-slide');
        let htmlWidth = document.querySelector('.html-link-course');
        let jsWidth = document.querySelector('.js-link-course');
        let frontWidth = document.querySelector('.front-link-course');
        let backWidth = document.querySelector('.back-link-course');

        for(let i=0; i<left.length; i++) {
         left[i].style.transform = 'translate(-100%)';
        }

        htmlWidth.style.width = '180px';
        jsWidth.style.width = '200px';
        frontWidth.style.width = '180px';
        backWidth.style.width = '180px';
     }

     frontTab = (left) => {
        left = document.querySelectorAll('.course-slide');
        let htmlWidth = document.querySelector('.html-link-course');
        let jsWidth = document.querySelector('.js-link-course');
        let frontWidth = document.querySelector('.front-link-course');
        let backWidth = document.querySelector('.back-link-course');

        for(let i=0; i<left.length; i++) {
         left[i].style.transform = 'translate(-200%)';
        }

        htmlWidth.style.width = '180px';
        jsWidth.style.width = '180px';
        frontWidth.style.width = '200px';
        backWidth.style.width = '180px';
     }

     backTab = (left) => {
        left = document.querySelectorAll('.course-slide');
        let htmlWidth = document.querySelector('.html-link-course');
        let jsWidth = document.querySelector('.js-link-course');
        let frontWidth = document.querySelector('.front-link-course');
        let backWidth = document.querySelector('.back-link-course');

        for(let i=0; i<left.length; i++) {
         left[i].style.transform = 'translate(-300%)';
        }

        htmlWidth.style.width = '180px';
        jsWidth.style.width = '180px';
        frontWidth.style.width = '180px';
        backWidth.style.width = '200px';
     }

    render() {
        return(
            <main className='courses-main'>
                <div className='courses-title-box'>
                    <h1>Դասընթացներ</h1>
                </div>
                <div className='courses-content-box'>
                    <div className='courses-cont-tit'>
                        <p>ծրագրավորում</p>
                    </div>
                    <div className='courses-cont'>

                        <div className='courses-links'>
                            <div className='courses-link-box' onClick={this.htmlTab}>
                                <span className='courses-icon course-html'></span>
                                <h2 className='html-link-course  link-course'>HTML & CSS</h2>
                            </div>
                            <div className='courses-link-box' onClick={this.jsTab}>
                                <span className='courses-icon course-js'></span>
                                <h2 className='js-link-course link-course'>JavaScript</h2>
                            </div>
                            <div className='courses-link-box' onClick={this.frontTab}>
                                <span className='courses-icon course-front'></span>
                                <h2 className='front-link-course link-course'>Frontend</h2>
                            </div>
                            <div className='courses-link-box' onClick={this.backTab}>
                                <span className='courses-icon course-back'></span>
                                <h2 className='back-link-course link-course'>backend</h2>
                            </div>
                        </div>

                        <div className='courses-tabs'>

                            <div className='course-slide-box'>
                                <div className='course-slide'>
                                    <div className='courses-tabs-tit'>
                                        <h2>HTML և CSS</h2>
                                    </div>
                                    <div className='courses-tab-cont'>
                                        <p>ՈՒսուցանվող լեզուներ html, css, bootstrap:</p>
                                        <p>Տևողությունը 2 ամիս, շաբաթական 3 դաս 1․5-ից 2 ժամ։</p>
                                        <p>Դասընթացների տեսակը անհատական։</p>
                                        <p>Դասընթացները նախատեսված են 16 տարեկան և բարձր տարիքային մարդկանց համար։</p>
                                        <p>գրանցվելու համար կարող եք դիմել մեզ օնլայն տարբերակով կամ զանգահարել:</p>
                                        <p className='courses-date'>
                                            <span className='courses-date-icon'></span>
                                            10/15/2023
                                        </p>
                                    </div>
                                </div>

                                <div className='course-slide'>
                                    <div className='courses-tabs-tit'>
                                        <h2>JavaScript</h2>
                                    </div>
                                    <div className='courses-tab-cont'>
                                        <p>ՈՒսուցանվող լեզուներ javaScript:</p>
                                        <p>Տևողությունը 2 ամիս, շաբաթական 3 դաս 1․5-ից 2 ժամ։</p>
                                        <p>Դասընթացների տեսակը անհատական։</p>
                                        <p>Դասընթացները նախատեսված են 16 տարեկան և բարձր տարիքային մարդկանց համար։</p>
                                        <p>գրանցվելու համար կարող եք դիմել մեզ օնլայն տարբերակով կամ զանգահարել:</p>
                                        <p className='courses-date'>
                                            <span className='courses-date-icon'></span>
                                            10/15/2023
                                        </p>
                                    </div>
                                </div>

                                <div className='course-slide'>
                                    <div className='courses-tabs-tit'>
                                        <h2>Front End</h2>
                                    </div>
                                    <div className='courses-tab-cont'>
                                        <p>ՈՒսուցանվող լեզուներ html, css, bootstrap, javaScript:</p>
                                        <p>Տևողությունը 5-ից 6 ամիս, շաբաթական 3 դաս 1․5-ից 2 ժամ։</p>
                                        <p>Դասընթացների տեսակը անհատական։</p>
                                        <p>Դասընթացները նախատեսված են 16 տարեկան և բարձր տարիքային մարդկանց համար։</p>
                                        <p>գրանցվելու համար կարող եք դիմել մեզ օնլայն տարբերակով կամ զանգահարել:</p>
                                        <p className='courses-date'>
                                            <span className='courses-date-icon'></span>
                                            10/15/2023
                                        </p>
                                    </div>
                                </div>

                                <div className='course-slide'>
                                    <div className='courses-tabs-tit'>
                                        <h2>Back End</h2>
                                    </div>
                                    <div className='courses-tab-cont'>
                                        <p>ՈՒսուցանվող լեզուներ php, mysql:</p>
                                        <p>Տևողությունը 2-ից 3 ամիս, շաբաթական 3 դաս 1․5-ից 2 ժամ։</p>
                                        <p>Դասընթացների տեսակը անհատական։</p>
                                        <p>Դասընթացները նախատեսված են 16 տարեկան և բարձր տարիքային մարդկանց համար։</p>
                                        <p>գրանցվելու համար կարող եք դիմել մեզ օնլայն տարբերակով կամ զանգահարել:</p>
                                        <p className='courses-date'>
                                            <span className='courses-date-icon'></span>
                                            10/15/2023
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='course-tab-buttons'>
                                <Link to='/register'>Գրանցվել</Link>
                                <Link to='/contact'>ուղարկել նամակ</Link>
                                <Link>զանգահարել</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
export default Courses;