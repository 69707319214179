import React from "react";
import { Link } from "react-router-dom";

class Tab extends React.Component{

    close = () => {
        document.querySelector('.tab').style.display = 'none';
    }

    render() {
        return(
            <div className="tab">
                <div className="tab-content">
                    <div className="tab-close">
                        <span onClick={this.close}></span>
                    </div>
                    <div className="tab-title">
                        <h2>զանգահարել</h2>
                    </div>
                    <div className="tab-phone">
                        <Link to='tel:+37477736770'>
                            <span className="ph-ic"></span>
                            (077) 736-770
                        </Link>
                        <Link to='viber://chat?number=37477736770'>
                            <span className="vi-ic"></span>
                            (077) 736-770
                        </Link>
                        <Link to='https://wa.me/37477736770'>
                            <span className="wa-ic"></span>
                            (077) 736-770
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}
export default Tab;