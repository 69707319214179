import React from "react";

class Section5 extends React.Component{
    render() {
        return(
            <section className="main-sec-5">
                <div className="sec-5-title">
                    <h2>Պատվերի ընթացք</h2>
                    <p>Մենք ձեր բիզնեսը դարձնում ենք հասանելի օնլայն հարթակում։ Հետաքրքրե՞ց ուրեմն սկսենք:</p>
                </div>
                <div className="sec-5-content">
                    <div className="sec-5-cont-box">
                        <div className="sec-5-bg">
                            <img src={require('../image/call.png')} alt="" />
                        </div>
                        <div className="sec-5-cont">
                            <h3>ԿԱՊՎԵԼ ՄԵԶ ՀԵՏ</h3>
                            <p>Մենք ձեզ համար հնարավորինս հարմար ենք դարձնում պատվերի գործընթացը</p>
                        </div>
                    </div>

                    <div className="sec-5-cont-box">
                        <div className="sec-5-bg">
                            <img src={require('../image/choose.png')} alt="" />
                        </div>
                        <div className="sec-5-cont">
                            <h3>ԸՆՏՐԵԼ ԾԱՌԱՅՈՒԹՅՈՒՆԸ</h3>
                            <p>Մենք կօգնենք ձեզ առանց որևէ դժվարության անցնել նախագծից մինչև վերջնական արդյունքի:</p>
                        </div>
                    </div>

                    <div className="sec-5-cont-box">
                        <div className="sec-5-bg">
                            <img src={require('../image/checklist.png')} alt="" />
                        </div>
                        <div className="sec-5-cont">
                            <h3>ԾԱՆՈԹԱՆԱԼ ՊԱՅՄԱՆՆԵՐԻՆ</h3>
                            <p>Ծառայությունը ընտրելուց հետո որոշվում է ամենամատչելի պայմանները, և սկսում ենք նախագիծը։</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Section5;