import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component{

    scrollTop = () => {
        window.scrollTo(0,0);
    }

    render() {
        return(
            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-social">
                        <div className="footer-logo-box">
                            <img src={require('./image/logo.png')} alt="" />
                        </div>
                        <div className="footer-social-icons">
                            <Link to='' alt='facebook'>
                                <img src={require('./image/facebook.png')} alt="" />
                            </Link>
                            <Link to='' alt='instagram'>
                                <img src={require('./image/instagram.png')} alt="" />
                            </Link>
                            <Link to='' alt='linkedin'>
                                <img src={require('./image/linkedin.png')} alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className="footer-links">
                        <div className="footer-link-box">
                            <h2>ԸՆԿԵՐՈՒԹՅՈՒՆ</h2>
                            <span className="footer-link-line"></span>
                            <Link to='/' onClick={this.scrollTop}>Գլխավոր</Link>
                            <Link to='contact' onClick={this.scrollTop}>Կոնտակտ</Link>
                            <Link to='about' onClick={this.scrollTop}>Մեր Մասին</Link>
                        </div>

                        <div className="footer-link-box">
                            <h2>ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐ</h2>
                            <span className="footer-link-line"></span>
                            <Link to='courses' onClick={this.scrollTop}>Դասընթացներ</Link>
                            <Link to='construct' onClick={this.scrollTop}>Ծրագրավորում</Link>
                            <Link to='construct' onClick={this.scrollTop}>Դիզայն</Link>
                            <Link to='construct' onClick={this.scrollTop}>Առաջխաղացում</Link>
                        </div>

                        <div className="footer-link-box">
                            <h2>ԿՈՒՐՍԵՐ</h2>
                            <span className="footer-link-line"></span>
                            <Link to='courses' onClick={this.scrollTop}>HTML & CSS</Link>
                            <Link to='courses' onClick={this.scrollTop}>JavaScript</Link>
                            <Link to='courses' onClick={this.scrollTop}>Front End</Link>
                            <Link to='courses' onClick={this.scrollTop}>Back End</Link>
                        </div>

                        <div className="footer-link-box">
                            <h2>ԲԱԺԱՆՈՐԴԱԳՐՎԵԼ</h2>
                            <span className="footer-link-line"></span>
                            <input type="email" placeholder="enter your email" />
                            <button>ԲԱԺԱՆՈՐԴԱԳՐՎԵԼ</button>
                        </div>
                    </div>
                </div>
                <div className="copyright-box">
                    <p>© 2023 Բոլոր իրավունքները պաշտպանված են | digicode.am</p>
                </div>
            </footer>
        );
    }
}
export default Footer;