import React from "react";
import Section1 from "./section_1";
import Section2 from "./section_2";
import Section3 from "./section_3";
import Section4 from "./section_4";
import Section5 from "./section_5";

class Main extends React.Component{
    render() {
        return(
            <main className="main">
                <Section1 />
                <Section2 />
                <Section3 />
                <Section4 />
                <Section5 />
            </main>
        );
    }
}
export default Main;