import React from "react";
import { Link } from "react-router-dom";

class Section3 extends React.Component{

    scrollTop = () => {
        window.scrollTo(0,0);
    }

    render() {
        return(
            <section className="main-sec-3">

                <div className="sec-3-title">
                    <h3>
                        <span></span>
                        ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐ
                    </h3>
                    <h2>
                        ինչ ենք մենք առաջարկում
                        <span></span>
                    </h2>
                </div>

                <div className="sec-3-content">
                    <div className="sec-3-cont-box">
                        <div className="sec-3-cont-bg">
                            <img src={require('../image/prog.png')} alt="" />
                        </div>
                        <p>Վեբ ծրագրավորում</p>
                        <Link to='construct' onClick={this.scrollTop}>
                            իմանալ ավելին
                            <span className="arrow-icon"></span>
                        </Link>
                    </div>

                    <div className="sec-3-cont-box">
                        <div className="sec-3-cont-bg">
                            <img src={require('../image/seo.png')} alt="" />
                        </div>
                        <p>Առաջխաղացում (seo)</p>
                        <Link to='construct' onClick={this.scrollTop}>
                            իմանալ ավելին
                            <span className="arrow-icon"></span>
                        </Link>
                    </div>

                    <div className="sec-3-cont-box">
                        <div className="sec-3-cont-bg">
                            <img src={require('../image/design1.png')} alt="" />
                        </div>
                        <p>Դիզայն</p>
                        <Link to='construct' onClick={this.scrollTop}>
                            իմանալ ավելին
                            <span className="arrow-icon"></span>
                        </Link>
                    </div>

                    <div className="sec-3-cont-box">
                        <div className="sec-3-cont-bg">
                            <img src={require('../image/teacher.png')} alt="" />
                        </div>
                        <p>Դասընթացներ</p>
                        <Link to='courses' onClick={this.scrollTop}>
                            իմանալ ավելին
                            <span className="arrow-icon"></span>
                        </Link>
                    </div>
                </div>
                
            </section>
        )
    }
}
export default Section3;