import React from "react";

class Section4 extends React.Component{

    openTab = () => {
        document.querySelector('.tab').style.display = 'flex';
    }

    render(){
        return(
            <section className="main-sec-4">
                <h3>
                    <span className="sec-4-line"></span>
                    Մենք առաջարկում ենք որակյալ ծառայություններ։
                </h3>
                <h2>Վեբ կայքերի նախագծում, և պատրաստում</h2>
                <p>Բիզնես կայքերի առաջխաղացում համացանցում, և տեխնիկական վերլուծություն որոնողական ալգորիթմների պահանջներին համապատասխան։</p>
                <button onClick={this.openTab}>զանգահարել</button>
            </section>
        );
    }
}
export default Section4;