import React from "react";

class Reg extends React.Component{
    render() {
        return(
            <main className="reg-main-box">
                <section className="reg-tit">
                    <div className="reg-logo"></div>
                    <div className="reg-cont">
                        <p>Թողեք ձեր տվյալները, և մեր մասնագետը կարճ ժամանակում կապ կհաստատի ձեզ հետ:</p>
                    </div>
                </section>
                <section className="reg-form">
                    <form method="post">

                        <span>Անուն</span>
                        <input type="text" placeholder="Անուն"/>

                        <span>Ազգանուն</span>
                        <input type="text" placeholder="Ազգանուն"/>

                        <span>Էլ․ հասցե</span>
                        <input type="text" placeholder="Էլ․ հասցե"/>

                        <span>Հեռախոսահամար</span>
                        <input type="number" placeholder="Հեռախոսահամար" />

                        <button>ՈԻղարկել</button>
                    </form>
                </section>
            </main>
        )
    }
}
export default Reg;