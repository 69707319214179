import React from "react";
import {Link} from "react-router-dom";

class Notfound extends React.Component{
    render() {
        return(
            <div className="notfound-box">
                <h1>404</h1>
                <p>Էջը չի գտնվել</p>
            </div>
        );
    }
}
export default Notfound;