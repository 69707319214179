import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import Tab from "./tab";

class Header extends React.Component{
    
    openMenu = () => {
        document.querySelector('.menu').style.transform = 'translateX(0)';
    }
    
    closeMenu = () => {
        document.querySelector('.menu').style.transform = 'translateX(100%)';
        document.querySelector('.menu-link-service').style.height = '45px';
    }

    service = () => {
        let serviceLink = document.querySelector('.menu-link-service');

        if(serviceLink.clientHeight == '44') {
            serviceLink.style.height = '225px';
            document.querySelector('.service-ul').style.display = 'flex';
            document.querySelector('.service-li-p').style.marginLeft = '5px';
        }
        else{
            serviceLink.style.height = '45px';
            document.querySelector('.service-ul').style.display = 'none';
            document.querySelector('.service-li-p').style.marginLeft = '0';
        }
    }

    scroll = document.addEventListener('scroll',() => {
        useState.setScroll = window.scrollY;
        let client = document.querySelector('#root').clientWidth;

        if(client <= 567) {
            if(useState.setScroll >= 100) {
                document.querySelector('.header').style.height = '60px';
            }else{
                document.querySelector('.header').style.height = '80px';
            }
        }else{
            if(useState.setScroll >= 100) {
                document.querySelector('.header').style.height = '80px';
            }else{
                document.querySelector('.header').style.height = '100px';
            }
        }
    });

    scrollTop = () => {
        document.querySelector('.menu').style.transform = 'translateX(100%)';
        window.scrollTo(0,0)
    }
     

    render() {
        return(
            <>
                <Tab />
                <div className="menu">
                    <div className="close-box" onClick={this.closeMenu}></div>
                    <div className="menu-box">
                        <div className="menu-logo-box">
                            <img src={require('./image/logo.png')} alt="" className="menu-logo-img" />
                        </div>
                        <ul className="menu-ul">
                            <div className="menu-link-box">
                                <li>
                                    <Link onClick={this.scrollTop} className="menu-link" to='/'>Գլխավոր</Link>
                                </li>
                            </div>
                            <div className="menu-link-box menu-link-service">
                                <li onClick={this.service} className="service-li">
                                    <p className="service-li-p">Ծառայություններ</p>
                                    <ul className="service-ul">
                                        <li>
                                            <Link onClick={this.scrollTop} to='courses'>HTML & CSS</Link>
                                        </li>
                                        <li>
                                            <Link onClick={this.scrollTop} to='courses'>JavaScript</Link>
                                        </li>
                                        <li>
                                            <Link onClick={this.scrollTop} to='courses'>Front End</Link>
                                        </li>
                                        <li>
                                            <Link onClick={this.scrollTop} to='courses'>Back End</Link>
                                        </li>
                                    </ul>
                                </li>
                            </div>
                            <div className="menu-link-box">
                                <li>
                                    <Link onClick={this.scrollTop} className="menu-link" to='contact'>Կոնտակտ</Link>
                                </li>
                            </div>
                            <div className="menu-link-box">
                                <li>
                                    <Link onClick={this.scrollTop} className="menu-link" to='about'>Մեր մասին</Link>
                                </li>
                            </div>
                        </ul>
                        <div>

                        </div>
                    </div>
                </div>
                <header className="header" >
                    <div className="logo-box">
                        <Link to="" onClick={this.scrollTop}>
                            <img src={require('./image/logo.png')} alt="logo" />
                        </Link>
                    </div>
                    <div className="menu-open" onClick={this.openMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </header>
            </>
        );
    }
}
export default Header;